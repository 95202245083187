@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:wght@300;700&display=swap");

* {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.btn,
.form-control {
  height: 45px;
  border-radius: 10px;
}

.primaryBtn {
  background: #0f4d92;
  color: #fff;
}

.primaryBtn:hover {
  background: #0f4d92;
  color: #fff;
}

.weight {
  font-weight: initial;
}

.hv100 {
  height: 700px;
}

.header100 {
  min-height: 100vh;
  height: 100%;
}

h1 {
  font-size: 30px;
}

.bullet {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  background: #0f4d92;
  font-size: 11px;
}

.bullet2 {
  padding: 5px;
  width: 50px;
  border-radius: 10px;
  background: #0f4d92;
  font-size: 10px;
}

.faq {
  border: solid 1px rgba(0, 0, 0, 0.05);
}

#header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/public/img/header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 550px;
}

#headerSignup {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/public/img/header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}

#cityHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/public/img/citybg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#airportHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/public/img/airportbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#contactHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/public/img/contact.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

a {
  text-decoration: none;
  color: #fff;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  box-shadow: none;
}

.carClass {
  cursor: pointer;
}

.borderCust {
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}

select {
  width: 90px;
  background: #ffff;
  border: solid 1px #dddd;
  height: 45px;
}

.companion {
  height: 300px;
}

.partners {
  width: 100%;
  height: 50%;
}

.maptxt {
  font-size: 14px;
}

#partners img {
  width: 80%;
}

.logo {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 960px) {
  .companion {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  * {
    font-size: 14px;
  }

  .maptxt {
    font-size: 13px;
  }

  h1 {
    font-size: 25px;
  }

  .btn,
  .form-control,
  select {
    height: 40px;
    border-radius: 10px;
  }

  .social {
    width: 25px;
    height: 25px;
  }

  .play {
    width: 20px;
  }

  .safety {
    width: 50px;
    height: 50px;
  }

  .companion {
    height: 360px;
  }
}

@media screen and (max-width: 576px) {
  * {
    font-size: 13px;
  }

  .maptxt {
    font-size: 12px;
  }

  h1 {
    font-size: 20px;
  }

  .social {
    width: 20px;
    height: 20px;
  }

  .play {
    width: 16px;
  }

  .safety {
    width: 35px;
    height: 35px;
  }

  .companion {
    height: 300px;
  }
}

@media screen and (max-width: 480px) {
  .companion {
    height: 230px;
  }
}

@media screen and (max-width: 320px) {
  .container,
  .container-fluid {
    width: 320px;
  }

  .companion {
    height: 200px;
  }
}
